// Each role - prime number
export const Roles = {
	admin: 1,
	manager: 2,
	user: 3,
	teammanager: 5,
};

export const Permissions = {
	roleAuthenticatedUser: [Roles.admin, Roles.manager, Roles.user, Roles.teammanager],

	//project roles
	roleViewProject: [Roles.admin, Roles.manager, Roles.teammanager],
	roleAddProject: [Roles.admin, Roles.manager, Roles.teammanager],
	roleEditProject:[Roles.admin, Roles.manager, Roles.teammanager],
	roleChangeProjectStatus: [Roles.admin, Roles.manager, Roles.teammanager],
	roleAssignProjectManager: [Roles.admin, Roles.teammanager],
	roleAssignProjectMember: [Roles.admin, Roles.manager, Roles.teammanager],
	roleViewClient: [Roles.admin, Roles.teammanager],
	roleAddClient: [Roles.admin, Roles.teammanager],
	roleEditClient: [Roles.admin, Roles.teammanager],
	roleViewTask: [Roles.admin, Roles.teammanager],
	roleAddTask: [Roles.admin, Roles.teammanager],
	roleEditTask: [Roles.admin, Roles.teammanager],
	roleViewMember: [Roles.admin, Roles.teammanager],
	roleAddMember: [Roles.admin, Roles.teammanager],
	roleEditMember: [Roles.admin, Roles.teammanager],
	roleEditProjectRole : [Roles.admin],

	roleViewAdminPanel: [Roles.admin, Roles.teammanager],
	roleViewIntegrationPage: [Roles.admin, Roles.teammanager],

	//team roles
	roleViewTeam: [Roles.admin,Roles.teammanager],
	roleAddTeam: [Roles.admin,Roles.manager,Roles.teammanager],
	roleEditTeam: [Roles.admin,Roles.manager,Roles.teammanager],
	roleChangeTeamStatus: [Roles.admin,Roles.manager,Roles.teammanager],
	roleAssignTeamManager:[Roles.admin, Roles.teammanager],
	roleAssignTeamMember: [Roles.admin, Roles.manager, Roles.teammanager],
	roleViewMemberTeam: [Roles.admin, Roles.teammanager],
	roleAddMemberTeam: [Roles.admin, Roles.teammanager],
	roleEditMemberTeam: [Roles.admin, Roles.teammanager],
	roleViewUnassignedMembers: [Roles.admin, Roles.teammanager],

	roleAddPublicHoliday: [Roles.admin],
	roleEditPublicHoliday:[Roles.admin],
	roleDeletePublicHoliday:[Roles.admin],
	roleViewPublicHolidays: [Roles.admin,Roles.manager,Roles.teammanager],
};
