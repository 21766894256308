import { of as observableOf, Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagedResult, ODataServiceFactory, ODataService } from './odata';
import { ODataConfiguration } from './odata/config';
import { PublicHoliday } from 'app/models/public-holidays';

@Injectable()
export class PublicHolidayService {
    readonly odata: ODataService<PublicHoliday>;

    constructor(private http: HttpClient,
        private odataFactory: ODataServiceFactory,
        private odataConfig: ODataConfiguration) {
        this.odata = this.odataFactory.CreateService<PublicHoliday>('PublicHolidays');
    }

    public GetPublicHolidays(event, filterStr = ''):Observable<PagedResult<PublicHoliday>> {

            let filters = [];
            let query = this.odata
                .Query()
                .Top(event.rows)
                .Skip(event.first);

            if (event.sortField) {
                query.OrderBy(event.sortField + ' ' + (event.sortOrder === 1 ? 'asc' : 'desc'));
            } else {
                query.OrderBy('name' + ' ' + (event.sortOrder === 1 ? 'asc' : 'desc'));
            }
            if (filterStr) {
                filterStr = filterStr.replace("'", "''");
                filters.push('contains(tolower(name),\'' + filterStr.trim().toLowerCase() + '\')');
            }

            query.Filter(filters.join(' and '));

            return query.ExecWithCount().map(res => {
                res.data = res.data.map((x: Object) => new PublicHoliday(x));
                return res;
            });
    }

    getAllPublicHolidays() : Observable<any>
    {
        return this.odata
        .Query().Exec().map(res => {
            res = res.map((x: Object) => new PublicHoliday(x));
            return res;
        });
    }

    public deletePublicHoliday(id:number){
        let odata = this.odataFactory.CreateService<PublicHoliday>('PublicHolidays');

        return odata.Delete(id.toString());
    }
   
}