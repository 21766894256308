import { TeamRole } from "./team-role";

export class TeamMember {
    id: number;
    fullName: string;
    urlIcon: string;
    userName:string;
    email:string;
    isTeamManager:boolean;
    teamId:number;
    teamName:string;
    memberId:number;
    teamRoleId:number;
    teamRoleName:string;
    teamRole:TeamRole;
    isActive:boolean;
    teamsCount: number;

    constructor(data = null) {
        this.id = data && data.id;
        this.isActive = data && data.isActive;
        this.fullName = data && data.fullName;
        this.urlIcon = data && data.urlIcon;
        this.userName = data && data.userName;
        this.email = data && data.email;
        this.isTeamManager = data && data.isTeamManager;
        this.teamsCount = data && data.teamsCount;
        this.teamRoleId = data && data.teamRoleId;
        this.teamRoleName = data && data.teamRoleName;
        this.teamRole = data && data.teamRole;
        this.teamId = data && data.teamId;
        this.teamName = data && data.teamName;
        this.memberId = data && data.memberId;
    }
}