import { of as observableOf, Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagedResult, ODataServiceFactory, ODataService } from './odata';
import { ODataConfiguration } from './odata/config';
import { Team } from '../models/team'; 
import { User } from 'app/models/user';
import { TeamMember } from 'app/models/team-member';

@Injectable()
export class TeamService {
    readonly odata: ODataService<Team>;

    constructor(private http: HttpClient,
        private odataFactory: ODataServiceFactory,
        private odataConfig: ODataConfiguration) {
        this.odata = this.odataFactory.CreateService<Team>('Teams');
    }

    public GetTeams(event, filterStr = '', isActive: boolean = true):Observable<PagedResult<Team>> {

            let filters = [];
            let query = this.odata
                .Query()
                .Top(event.rows)
                .Skip(event.first);

            if (event.sortField) {
                query.OrderBy(event.sortField + ' ' + (event.sortOrder === 1 ? 'asc' : 'desc'));
            } else {
                query.OrderBy('name' + ' ' + (event.sortOrder === 1 ? 'asc' : 'desc'));
            }
            if (filterStr) {
                filterStr = filterStr.replace("'", "''");
                filters.push('contains(tolower(name),\'' + filterStr.trim().toLowerCase() + '\')');
            }

            filters.push('isActive eq ' + isActive);
            query.Filter(filters.join(' and '));

            return query.ExecWithCount().map(res => {
                res.data = res.data.map((x: Object) => new Team(x));
                return res;
            });
    }

    public changeTeamRole(teamMemberId: number, teamRoleId: number): Observable<any> {
		let odata = this.odataFactory.CreateService<TeamMember>(`TeamMembers(${teamMemberId})`);
		let newTeamMember = {
			teamRoleId: teamRoleId,
		};

		return odata.Patch( newTeamMember,teamMemberId.toString());
    }

    public getUnassignedTeamMembers(event, filterStr = '', teamid: number): Observable<PagedResult<User>> {
		let odata = this.odataFactory.CreateService<User>('Teams(' + teamid + ')/UnassignedMembers');

		let filters = [];
		let query = odata
			.Query()
			.Top(event.rows)
			.Skip(event.first);

		if (event.sortField) {
			query.OrderBy('fullName ' + (event.sortOrder === 1 ? 'asc' : 'desc'));
		} else {
			query.OrderBy('fullName asc');
		}

		if (filterStr) {
            filterStr = filterStr.replace("'", "''");
			filters.push('contains(tolower(fullName),\'' + filterStr.trim().toLowerCase() + '\')');
		}

		filters.push('isActive eq true');
		query.Filter(filters.join(' and '));

		return query.ExecWithCount().map(res => {
			res.data = res.data.map((x: Object) => new User(x));
			return res;
		});
	}

    public getTeamMembers(event, filterStr = '', teamid: number): Observable<PagedResult<TeamMember>> {
		let odata = this.odataFactory.CreateService<TeamMember>('Teams(' + teamid + ')/TeamMembers');

		let filters = [];
		let query = odata
			.Query()
			.Top(event.rows)
			.Skip(event.first);

		if (event.sortField) {
			query.OrderBy('fullName ' + (event.sortOrder === 1 ? 'asc' : 'desc'));
		} else {
			query.OrderBy('fullName asc');
		}

		if (filterStr) {
            filterStr = filterStr.replace("'", "''");
			filters.push('contains(tolower(fullName),\'' + filterStr.trim().toLowerCase() + '\')');
		}

		filters.push('isActive eq true');
		query.Filter(filters.join(' and '));

		return query.ExecWithCount().map(res => {
			res.data = res.data.map((x: Object) => new TeamMember(x));
			return res;
		});
	}

    createTeamMember(teamMember:TeamMember) {
		let odata = this.odataFactory.CreateService<TeamMember>('TeamMembers');
		return odata.Post(teamMember);
    }

    public getTeamByName(name: string): Observable<Team> {
        name = name.trim().toLowerCase();
        if (!name) {
            throw new Error('Please specify team name');
        }

        let odata = this.odataFactory.CreateService<Team>('TeamsNames');

        let query = odata
            .Query()
            .Top(1);

        query.Filter('tolower(name) eq \'' + name + '\'');

        return query.Exec().pipe(
            mergeMap((result) => {
                let team = result[0] ? new Team(result[0]) : null;
                return observableOf(team);
            })
        );
    }

    public deleteTeamMember(id:number){
        let odata = this.odataFactory.CreateService<TeamMember>('TeamMembers');

        return odata.Delete(id.toString());
    }
}