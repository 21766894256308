import { TimeEntriesReviewsService } from './../../../services/member-time-entries-reviews.service';
import { finalize } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../../../models/user';
import { AclService } from '../../../core/auth/acl.service';
import { AuthUser } from '../../../core/auth/auth-user';
import { AuthService } from '../../../core/auth/auth.service';
import { AuthGuard } from '../../../core/auth/auth-guard.service';
import { ImpersonationService } from '../../../services/impersonation.service';
import { ProjectsService } from '../../../services/projects.service';
import { UsersService } from '../../../services/users.service';
import { LoadingMaskService } from '../../../shared/loading-indicator/loading-mask.service';
import { TeamService } from '../../../services/team.service';
import { Roles, Permissions } from 'app/core/auth/permissions';
import { Router } from '@angular/router';
import { ManagerReviewsService } from 'app/services/manager-time-entries-reviews.service';

interface MenuItem {
	label?: string;
	icon?: string;
	routerLink?: any;
	permission?: string;
}

const FULL_MANAGE_ITEMS = [
	{
		label: 'Projects',
		icon: 'ct-projects-icon',
		routerLink: ['/projects'],
		permission: 'roleViewProject',
	},
	{
		label: 'Teams',
		icon: 'ct-teams-icon',
		routerLink: ['/teams'],
		permission: 'roleViewTeam',
	},
	{
		label: 'Clients',
		icon: 'ct-clients-icon',
		routerLink: ['/clients'],
		permission: 'roleViewClient',
	},
	{
		label: 'Tasks',
		icon: 'ct-tasks-icon',
		routerLink: ['/tasks'],
		permission: 'roleViewTask',
	},
	{
		label: 'Public Holidays',
		icon: 'ct-projects-icon',
		routerLink: ['/public-holidays'],
		permission: 'roleViewPublicHolidays',
	},
	{
		label: 'Users',
		icon: 'ct-users-icon',
		routerLink: ['/users'],
		permission: 'roleViewMember',
	},
	{
		label: 'Admin',
		icon: 'ct-admin-icon',
		routerLink: ['/admin'],
		permission: 'roleViewAdminPanel',
	},
	{
		label: 'Approvals',
		icon: 'ct-done-icon',
		routerLink: ['/manager/reviews'],
		permission: 'roleViewMemberTeam',
	},
];

@Component({
	selector: 'ct-navigation',
	templateUrl: 'navigation.component.html',
})
export class NavigationComponent implements OnInit, OnDestroy {
	authUser: AuthUser;
	impersonationUser: User;
	items: MenuItem[];
	manageItems: MenuItem[];
	showManageMenu: boolean = false;
	showManageTeamMenu: boolean = false;
	userInfo: User;
	windowWidth: number;

	private subscriptionImpersonation: Subscription;
	private subscriptionUserInfo: Subscription;

	constructor(
		private authService: AuthService,
		private aclService: AclService,
		private auth: AuthGuard,
		private impersonationService: ImpersonationService,
		private loadingService: LoadingMaskService,
		private projectsService: ProjectsService,
		private usersService: UsersService,
		private teamService: TeamService,
		private managerReviewsService : ManagerReviewsService,
	) {}

	ngOnInit() {
		this.authUser = this.authService.authUser;

		this.getUserInfo();
		this.onResize();
		this.updateManageMenuVisibility();

		this.subscriptionUserInfo = this.usersService.onChange.subscribe((userInfo: User) => {
			this.userInfo = userInfo;
		});
		this.subscriptionImpersonation = this.impersonationService.onChange.subscribe(() => {
			this.updateManageMenuVisibility();
		});

		this.items = [
			{
				label: 'Time Tracker',
				icon: 'ct-timetracker-icon',
				routerLink: ['/calendar'],
			},
			{
				label: 'Reports',
				icon: 'ct-reports-icon',
				routerLink: ['/reports'],
			},
		];
	}

	isMobileView(): boolean {
		return this.windowWidth < 810;
	}

	getUserInfo(): void {
		this.loadingService.addLoading();
		this.usersService.getUserInfo(this.authUser.id).then((userInfo: User) => {
			this.loadingService.removeLoading();
			this.userInfo = userInfo;
		});
	}

	onResize(): void {
		this.windowWidth = window.innerWidth;
	}

	updateManageMenuVisibility(): void {
		this.impersonationUser = this.impersonationService.impersonationUser;
		this.manageItems = FULL_MANAGE_ITEMS;
		if (this.impersonationUser) {
			let isManager = Roles[this.impersonationUser.role] == this.impersonationUser.role;
			let isAdmin = this.impersonationUser.isAdmin;
			this.showManageMenu = true;
			this.authService.isUserAdminOrManager = true;

			if (isManager && !isAdmin) {
				this.manageItems = [FULL_MANAGE_ITEMS[0]];
			}

			return;
		}

		if (this.aclService.isGranted(Permissions.roleAddProject)) {
			this.showManageMenu = true;
			this.authService.isUserAdminOrManager = true;
		}

		this.loadingService.addLoading();
		this.projectsService
			.getManagerProjectsCount()
			.pipe(finalize(() => this.loadingService.removeLoading()))
			.subscribe((count) => {
				if (!this.aclService.isGranted(Permissions.roleViewAdminPanel)) {
					this.showManageMenu = !!count;
					this.authService.isUserAdminOrManager = !!count;
					this.authService.adminOrManagerParameterOnChange.emit();
				}
			});
	}

	signOut(): void {
		this.auth.url = 'calendar';
		this.authService.logout();
	}

	ngOnDestroy() {
		this.subscriptionUserInfo.unsubscribe();
		this.subscriptionImpersonation.unsubscribe();
	}
}
