import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForbiddenComponent } from './pages/errors/components/forbidden/forbidden.component';
import { ServerErrorComponent } from './pages/errors/components/server-error/server-error.component';

export const appRoutes: Routes = [
	{
		path: '',
		redirectTo: 'calendar',
		pathMatch: 'full',
	},
	{
		path: 'home',
		loadChildren: () => import('../app/pages/home/home.module').then((m) => m.HomeModule),
	},
	{
		path: 'signin-oidc',
		loadChildren: () => import('../app/pages/signin-oidc/signin-oidc.module').then((m) => m.SignInOidcModule),
	},
	{
		path: 'profile',
		loadChildren: () => import('../app/pages/profile/profile.module').then((m) => m.ProfileModule),
	},
	{
		path: 'projects',
		loadChildren: () => import('../app/pages/projects/projects.module').then((m) => m.ProjectsModule),
	},
	{
		path: 'clients',
		loadChildren: () => import('../app/pages/clients/clients.module').then((m) => m.ClientsModule),
	},
	{
		path: 'calendar',
		loadChildren: () => import('../app/pages/calendar/calendar.module').then((m) => m.CalendarModule),
	},
	{
		path: 'about',
		loadChildren: () => import('../app/pages/about/about.module').then((m) => m.AboutModule),
	},
	{
		path: 'login',
		loadChildren: () => import('../app/pages/login/login.module').then((m) => m.LoginModule),
	},
	{
		path: 'set-password',
		loadChildren: () => import('../app/pages/set-password/set-password.module').then((m) => m.SetPasswordModule),
	},
	{
		path: 'tasks',
		loadChildren: () => import('../app/pages/tasks/tasks.module').then((m) => m.TasksModule),
	},
	{
		path: 'users',
		loadChildren: () => import('../app/pages/users/users.module').then((m) => m.UsersModule),
	},
	{
		path: 'admin',
		loadChildren: () => import('../app/pages/admin/admin.module').then((m) => m.AdminModule),
	},
	{
		path: 'reports',
		loadChildren: () => import('../app/pages/reports/reports.module').then((m) => m.ReportsModule),
	},
	{
		path: 'vsts-integration',
		loadChildren: () => import('../app/pages/vsts-integration/vsts-integration.module').then((m) => m.VstsIntegrationModule),
	},
	{
		path: 'teams',
		loadChildren: () => import('../app/pages/teams/teams.module').then((m) => m.TeamsModule),
	},
	{
		path:'public-holidays',
		loadChildren:() => import('../app/pages/public-holidays/public-holidays.module').then((m) => m.PublicHolidaysModule),
	},
	{
		path: 'manager/reviews',
		loadChildren: () => import('../app/pages/manager/reviews/members-time-entries/members-time-entries.module').then((m) => m.MembersTimeEntriesModule),
	},
	{
		path: 'error',
		component: ServerErrorComponent,
	},
	{
		path: 'page-not-found',
		component: ForbiddenComponent,
	},
	{
		path: '**',
		component: ForbiddenComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
