export class PublicHoliday {
    id: number;
    name: string;
    date: Date;

    constructor(data = null) {
        this.id = data && data.id;
        this.name = data && data.name;
        this.date = data && data.date;
    }
}