import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PagedResult, ODataServiceFactory, ODataService } from './odata';
import { Team } from '../models/team';
import { TeamMember } from 'app/models/team-member';

@Injectable()
export class ManagerService {
    readonly odata: ODataService<TeamMember>;

    constructor(private odataFactory: ODataServiceFactory) {
        this.odata = this.odataFactory.CreateService<TeamMember>('Managers');
    }

    public GetManagers(event, filterStr = '', isActive: boolean = true):Observable<PagedResult<TeamMember>> {

            let filters = [];
            let query = this.odata
                .Query()
                .Top(event.rows)
                .Skip(event.first);

            if (event.sortField) {
                query.OrderBy(event.sortField + ' ' + (event.sortOrder === 1 ? 'asc' : 'desc'));
            } else {
                query.OrderBy('name' + ' ' + (event.sortOrder === 1 ? 'asc' : 'desc'));
            }
            if (filterStr) {
				filterStr = filterStr.replace("'", "''");
                filters.push('contains(tolower(name),\'' + filterStr.trim().toLowerCase() + '\')');
            }

            filters.push('isActive eq ' + isActive);
            query.Filter(filters.join(' and '));

            return query.ExecWithCount().map(res => {
                res.data = res.data.map((x: Object) => new TeamMember(x));
                return res;
            });
    }


    public GetManagerTeamMembers(event, filterStr = '', managerId: number): Observable<PagedResult<TeamMember>> {
		let odata = this.odataFactory.CreateService<TeamMember>('Managers(' + managerId + ')/TeamMembers');

		let filters = [];
		let query = odata
			.Query()
			.Top(event.rows)
			.Skip(event.first);

		if (event.sortField) {
			query.OrderBy('fullName ' + (event.sortOrder === 1 ? 'asc' : 'desc'));
		} else {
			query.OrderBy('fullName asc');
		}

		if (filterStr) {
			filterStr = filterStr.replace("'", "''");
			filters.push('contains(tolower(fullName),\'' + filterStr.trim().toLowerCase() + '\')');
		}

		filters.push('isActive eq true');
		query.Filter(filters.join(' and '));

		return query.ExecWithCount().map(res => {
			res.data = res.data.map((x: Object) => new TeamMember(x));
			return res;
		});
	}

    public GetManagerTeams(event, filterStr = '', managerId: number): Observable<PagedResult<Team>> {
		let odata = this.odataFactory.CreateService<Team>('Managers(' + managerId + ')/Teams');

		let filters = [];
		let query = odata
			.Query()
			.Top(event.rows)
			.Skip(event.first);

		if (event.sortField) {
			query.OrderBy('name ' + (event.sortOrder === 1 ? 'asc' : 'desc'));
		} else {
			query.OrderBy('name asc');
		}

		if (filterStr) {
			filterStr = filterStr.replace("'", "''");
			filters.push('contains(tolower(name),\'' + filterStr.trim().toLowerCase() + '\')');
		}

		filters.push('isActive eq true');
		query.Filter(filters.join(' and '));

		return query.ExecWithCount().map(res => {
			res.data = res.data.map((x: Object) => new Team(x));
			return res;
		});
	}
}