import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ODataServiceFactory, ODataService } from './odata';
import { TeamRole } from '../models/team-role';

@Injectable()
export class TeamRoleService {
    readonly odata: ODataService<TeamRole>;

    constructor(private odataFactory: ODataServiceFactory) {
        this.odata = this.odataFactory.CreateService<TeamRole>('TeamRoles');
    }

    getTeamRoles(): Observable<TeamRole[]> {
        return this.odata.Query().Exec().map((res: any) => {
            return res.map((x: Object) => new TeamRole(x));
        });
    }
}
