import { first, map, switchMap, take, debounceTime } from 'rxjs/operators';
import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { TeamService } from '../../services/team.service';
import { Team } from '../../models/team';

@Directive({
    selector: '[ctTeamNameValidator][formControlName],[ctTeamNameValidator][formControl],[ctTeamNameValidator][ngModel]',
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => TeamNameValidator),
            multi: true
        }
    ]
})

export class TeamNameValidator implements Validator {
    @Input('ctTeamNameValidator') team: Team;

    constructor(private teamService: TeamService) {
    }

    validate(control: AbstractControl): Observable<{ [key: string]: any }> {
        return control.valueChanges.pipe(
            debounceTime(500),
            take(1),
            switchMap(() => {
                return this.teamService.getTeamByName(control.value);
            }),
            map((team) => {
                if (team && (!this.team || team.id !== this.team.id)) {
                    return { ctTeamNameInvalid: true };
                }

                return null;
            }),
            first()
        );
    }
}
