export class Team {
    id: number;
    isActive: boolean;
    isCurrentUserOnTeam: boolean;
    teamMembersCount: number;
    description:string;
    name: string;

    constructor(data = null) {
        this.id = data && data.id;
        this.isActive = data && data.isActive;
        this.isCurrentUserOnTeam = data && data.isCurrentUserOnTeam;
        this.teamMembersCount = data && data.teamMembersCount;
        this.name = data && data.name;
        this.description = data && data.description;
    }
}
