import { Injectable } from '@angular/core';

export const ROWS_ON_PAGE = 15;
export const EMAIL_PATTERN = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/;
export const URL_PATTERN = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i;

@Injectable()
export class ConstantService {
    apiBaseUrl = '/api/v1';
    adminApi = '/api/v1/Admin/';
    profileApi: string = '/api/v1/Profile';
    reportsApi: string = '/api/v1/Reports';
    timeEntriesApi: string = '/api/v1/TimeEntries/';
    timeEntriesReviewsApi: string = '/api/v1/odata/TimeEntries/Reviews/Submit';
    timeEntriesStatusesApi: string = '/api/v1/odata/TimeEntryStatus';
    submitTimeEntriesForApprovalApi: string = '/api/v1/odata/ManagerTeamTimeEntries/Approve';
    remindToSubmit: string = '/api/v1/odata/ManagerTeamTimeEntries/RemindToSubmit';
    reopenAndReject: string = '/api/v1/odata/ManagerTeamTimeEntries/ReopenAndReject';
}

export class TimeEntryStatusMeta {
    public static readonly unsubmitted = 'Unsubmitted';
    public static readonly pending = 'Pending';
    public static readonly approved = 'Approved';
    public static readonly rejected = 'Rejected';
    public static readonly reopened = 'Reopened';
}

export const TimeEntryStatusMapping = [{
    id: 1,
    description: TimeEntryStatusMeta.unsubmitted
},
{
    id: 2,
    description: TimeEntryStatusMeta.pending
},
{
    id: 3,
    description: TimeEntryStatusMeta.approved
},
{
    id: 4,
    description: TimeEntryStatusMeta.rejected
},
{
    id: 5,
    description: TimeEntryStatusMeta.reopened
},
];