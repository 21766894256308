import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Permissions, Roles } from './permissions';
import { ImpersonationService } from '../../services/impersonation.service';

@Injectable()
export class AclService {
	constructor(private authService: AuthService,
	            private impersonationService: ImpersonationService) {
	}

	isGranted(role: any): boolean {
		let permissions:number[];
		if (typeof(role) === "string"){
			permissions = Permissions[role];
		}
		else{
			permissions = (<number[]>role);
		}

		if (!this.authService.isLoggedIn()) {
			return false;
		}

		if (permissions === undefined && permissions !== null) {
			throw new Error('Role "' + role + '" doesn\'t exists.');
		}

		if (this.impersonationService.impersonationUser) {
			if (permissions.findIndex(x => x == this.impersonationService.impersonationUser.getPermissionRole()) != -1)
				return true; 
		} else {
			if (permissions.findIndex(x => x == this.authService.authUser.role) != -1){
				return true;
			}
		}

		return false;
	}
}
