import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantService } from 'app/core/constant.service';
import { DateUtils } from 'app/models/calendar';
import { MemberTimeEntry, RejectedTimeEntry } from 'app/models/member-time-entry';
import { ReportDropdowns } from 'app/models/reports';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ODataService, ODataServiceFactory, PagedResult } from './odata';
import Moment = moment.Moment;

@Injectable({
	providedIn: 'root',
})
export class ManagerReviewsService {
	readonly odata: ODataService<MemberTimeEntry>;
	drillDownActive: boolean = false;
	drillDownUsername : string = '';

	constructor(private http: HttpClient, private odataFactory: ODataServiceFactory, private constantService: ConstantService) {
		this.odata = this.odataFactory.CreateService<MemberTimeEntry>('ManagerTeamTimeEntries');
	}

	getManagerTeamTimeEntries(
		event,
		filterStr = '',
		dateFrom: Moment,
		dateTo: Moment,
		projectsIds: number[] = [],
		statusIds: number[] = []
	): Observable<PagedResult<MemberTimeEntry>> {
		let from = new Date(dateFrom.toString()).toDateString();
		let to = new Date(dateTo.toString()).toDateString();

		let queryUrl = `${this.constantService.apiBaseUrl}/Approvals?Top=${event.rows}&Skip=${event.first}&dateFrom=${from}&dateTo=${to}`;

		projectsIds.forEach((x) => {
			queryUrl = `${queryUrl}&ProjectIds=${x}`;
		});

		statusIds.forEach((x) => {
			queryUrl = `${queryUrl}&StatusIds=${x}`;
		});

		if (filterStr !== '') {
			queryUrl = `${queryUrl}&name=${filterStr}`;
		}

		return this.http.get(queryUrl).pipe(map((res) => <PagedResult<MemberTimeEntry>>res));
	}

	getFilterDropdowns(): Observable<ReportDropdowns> {
		const date = DateUtils.formatDateToString(new Date());
		return this.http.get(this.constantService.reportsApi + '?date=' + date).pipe(map((res) => <ReportDropdowns>res));
	}

	submitApprovedEntries(payload: RejectedTimeEntry[], dateFrom: string, dateTo: string): Observable<any> {
		let from = new Date(dateFrom).toDateString();
		let to = new Date(dateTo).toDateString();

		return this.http
			.patch(this.constantService.submitTimeEntriesForApprovalApi + `?dateFrom=${from}&dateTo=${to}`, payload)
			.pipe(map((res: Object) => new Boolean(res)));
	}

	remindToSubmit(payload: number[], dateFrom: string, dateTo: string): Observable<any> {
		let from = new Date(dateFrom).toDateString();
		let to = new Date(dateTo).toDateString();

		return this.http.patch(this.constantService.remindToSubmit + `?dateFrom=${from}&dateTo=${to}`, payload).pipe(map((res: Object) => new Boolean(res)));
	}

	reopenAndRejectEntries(payload: RejectedTimeEntry[], dateFrom: string, dateTo: string): Observable<any> {
		let from = new Date(dateFrom).toDateString();
		let to = new Date(dateTo).toDateString();

		return this.http.patch(this.constantService.reopenAndReject + `?dateFrom=${from}&dateTo=${to}`, payload).pipe(map((res: Object) => new Boolean(res)));
	}
}
